import * as api from '../utils/api';
import getLanguage from '../utils/getLanguage';
import { showNotification } from '../state/notifications';
import { setOrderId } from '../utils/order';
import { isBrowser } from '../utils';

export const createOrder = (
  shipmentNumber,
  doorCode,
  deliveryRemarks,
  buildingType,
  reachInstructions,
  paymentOptions,
  emailAddress,
  details
) => async (dispatch, getState) => {
  try {
    const language = getLanguage(getState());
    const response = await api.createWoltDeliveryOrder(
      shipmentNumber,
      doorCode,
      deliveryRemarks,
      buildingType,
      reachInstructions,
      paymentOptions,
      language,
      emailAddress,
      details
    );
    const { redirectUrl, orderId, transactionId } = response;
    if (redirectUrl) {
      setOrderId(orderId, transactionId);
      isBrowser && window.location.assign(redirectUrl);
      return;
    }
  } catch (error) {
    console.warn('Failed to create wolt delivery order:', error);
    dispatch(showNotification('genericApiError'));
  }
};
