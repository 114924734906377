/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '../components';
import FullHeightColumn from '../components/FullHeightColumn';
import { Form } from 'formik';
import Spinner from '../components/Spinner';
import { getCards } from '../state/session';
import { formatPrice } from '../utils/formatters';
import * as api from '../utils/api';
import FormField from '../components/FormField';
import { Select } from '../components';

const buildingTypes = ['apartment', 'house', 'office', 'other'];
const reachInstructionOptions = ['doorbellOrPhone', 'doorCode', 'doorIsOpen', 'other'];

const WoltOrderForm = ({
  shipment,
  values: { reachInstructions, streetAddress, postalCode, city },
  isSubmitting,
  handleSubmit,
  onChangePage,
  page,
}) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const [available, setAvailable] = useState();
  const [woltInfo, setWoltInfo] = useState();
  const [submitting, setSubmitting] = useState(false);

  const handleContinue = useCallback(() => {
    (async () => {
      setSubmitting(true);
      const response = await api
        .isWoltAvailable(shipment.shipmentNumber, streetAddress, postalCode, city, true)
        .catch(() => null);
      const available = response?.available || false;
      setAvailable(available);
      if (available) {
        setWoltInfo(response);
        onChangePage(page + 1);
      }
      setSubmitting(false);
    })();
  }, [page, onChangePage, streetAddress, postalCode, city, shipment]);

  return (
    <Form
      sx={{
        width: '100%',
        my: 2,
      }}
    >
      {page == 0 ? (
        <>
          {available == false ? (
            <p>{translate('wolt.invalidLocation')}</p>
          ) : (
            <>
              <p>{translate('wolt.label')}</p>
              <FormField name="streetAddress" label={translate('wolt.address')} />
              <FormField name="postalCode" label={translate('wolt.postalCode')} />
              <FormField name="city" label={translate('wolt.city')} />
              <FormField name="phoneNumber" label={translate('wolt.phoneNumber')} />

              <Button
                type="button"
                onClick={handleContinue}
                disabled={!streetAddress || !postalCode || !city || submitting}
                sx={{ mt: 4 }}
              >
                {translate('wolt.continue')}
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <FormField as={Select} name="buildingType" label={translate('delivery.agree.buildingType.buildingType')}>
            <option value="apartment">{translate('delivery.agree.buildingType.apartment')}</option>
            <option value="house">{translate('delivery.agree.buildingType.house')}</option>
            <option value="office">{translate('delivery.agree.buildingType.office')}</option>
            <option value="other">{translate('delivery.agree.buildingType.other')}</option>
          </FormField>
          <FormField
            as={Select}
            name="reachInstructions"
            label={translate('delivery.agree.reachInstructions.reachInstructions')}
          >
            <option value="doorbellOrPhone">{translate('delivery.agree.reachInstructions.doorbellOrPhone')}</option>
            <option value="doorCode">{translate('delivery.agree.reachInstructions.doorCode')}</option>
            <option value="doorIsOpen">{translate('delivery.agree.reachInstructions.doorIsOpen')}</option>
            <option value="other">{translate('delivery.agree.reachInstructions.other')}</option>
          </FormField>
          {reachInstructions != 'doorIsOpen' && reachInstructions != 'other' && (
            <FormField
              name="doorCode"
              label={
                reachInstructions == 'doorCode'
                  ? translate('delivery.agree.reachInstructions.doorCode')
                  : translate('delivery.agree.reachInstructions.nameOrNumber')
              }
            />
          )}
          <FormField name="deliveryRemarks" label={translate('delivery.agree.remarks')} />
          <Box sx={{ mb: 3, mt: 4, fontWeight: 'medium', fontSize: 3 }}>
            <span sx={{ mr: 2 }}>{translate('wolt.price')}:</span>
            {!woltInfo?.priceVatIncluded ? (
              <Spinner />
            ) : (
              <span sx={{ color: 'primary' }}>{formatPrice(woltInfo.priceVatIncluded)}</span>
            )}
          </Box>
          <Flex
            sx={{
              justifyContent: ['flex-end', null, 'flex-start'],
              mt: 4,
            }}
          >
            <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
              {translate('wolt.orderButton')}
            </Button>
          </Flex>
        </>
      )}
    </Form>
  );
};

const woltSchema = translate =>
  yup.object().shape({
    streetAddress: yup.string().required(translate('wolt.requiredField')),
    postalCode: yup.string().required(translate('wolt.requiredField')),
    city: yup.string().required(translate('wolt.requiredField')),
    phoneNumber: yup.string(),
    buildingType: yup.string().required(translate('wolt.requiredField')),
    reachInstructions: yup.string(),
    deliveryRemarks: yup.string(),
    doorCode: yup.string(),
  });

const WoltOrderFormik = withFormik({
  mapPropsToValues: ({ shipment, user }) => ({
    streetAddress: shipment?.receiverStreetAddress || '',
    postalCode: shipment?.receiverPostalCode || '',
    city: shipment?.receiverCity || '',
    phoneNumber: shipment?.receiverPhoneNumber || '',
    doorCode: user?.doorCode || '',
    deliveryRemarks: user?.deliveryRemarks,
    buildingType: user?.buildingType || buildingTypes[0],
    reachInstructions: user?.reachInstructions || reachInstructionOptions[0],
  }),

  validationSchema: ({ translate }) => woltSchema(translate),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'WoltForm',
})(WoltOrderForm);

export default ({ state, formSubmit }) => {
  const { shipment } = state || {};
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const [page, setPage] = useState(0);

  const onBackClick = useCallback(() => {
    if (page == 0) {
      window.history.back();
    } else {
      setPage(page - 1);
    }
  }, [page]);

  const onSubmit = useCallback(async values => {
    formSubmit(values);
  }, []);

  const onChangePage = useCallback(
    page => {
      setPage(page);
    },
    [page]
  );

  // preload user saved payment cards
  const user = useSelector(state => state.session.user);
  useEffect(() => {
    if (user && !user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  const formProps = {
    shipment,
    onSubmit,
    translate,
    onChangePage,
    page,
    user,
  };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('wolt.title')}</Styled.h1>

      <Flex
        sx={{
          minWidth: '400px',
          mr: 4,
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <WoltOrderFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};
