import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PaymentSelection } from '../components/Payment';
import { createOrder } from '../wolt-delivery/order';

export default ({ shipmentNumber, selection, goBack }) => {
  const dispatch = useDispatch();
  const [isProcessing, setProcessing] = useState(false);

  let details;

  const { streetAddress, postalCode, city, phoneNumber } = selection || {};
  if (streetAddress) {
    details = { streetAddress, postalCode, city, phoneNumber };
  }

  const onPayButton = async paymentOptions => {
    if (isProcessing) {
      return;
    }
    try {
      setProcessing(true);
      await dispatch(
        createOrder(
          shipmentNumber,
          selection.doorCode,
          selection.deliveryRemarks,
          selection.buildingType,
          selection.reachInstructions,
          paymentOptions,
          selection?.emailAddress,
          details
        )
      );
      setProcessing(false);
    } catch (error) {
      //
    }
  };

  return <PaymentSelection onPayButton={onPayButton} onBackClick={goBack} />;
};
