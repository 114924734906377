/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { isBrowser } from '../utils';
import { SendParcelWidget, TrackingSearchWidget } from '../components/widgets';
import AccountLayout from '../account/AccountLayout';
import * as analytics from '../utils/analytics';
import WoltOrderForm from '../wolt-delivery/WoltOrderForm';
import WoltPayment from '../home-delivery/WoltPayment';
import { FullHeightColumn, Link } from '../components';

export default ({ location: { state } = {}, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const [page, setPage] = useState(0);
  const [values, setValues] = useState({});
  const goBack = useCallback(() => {
    setPage(0);
  }, [setPage]);

  const stateKeys = Object.keys(state || {});
  const hasState = stateKeys.includes('shipment') || stateKeys.includes('order');

  if (!hasState) {
    try {
      state = isBrowser && JSON.parse(window.sessionStorage.getItem('buyWoltDelivery'));
    } catch (err) {
      // no-op
    }
  } else if (!state.saved) {
    // remember shipment if user refresh browser
    isBrowser && window.sessionStorage.setItem('buyWoltDelivery', JSON.stringify(state));
    state.saved = true;
  }

  const handleSubmit = useCallback(
    values => {
      setPage(1);
      setValues(values);
    },
    [state]
  );

  return (
    <AccountLayout
      title={translate('wolt.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
      sidebar={
        <Box>
          <SendParcelWidget sidebar sx={{ mb: 3 }} />
          <TrackingSearchWidget sidebar />
        </Box>
      }
    >
      {state && state.order ? (
        <FullHeightColumn>
          <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('delivery.agree.done')}</Styled.h1>
          <p>{translate('delivery.agree.woltOnTheWay', { shipmentNumber: state.order.shipmentNumber })}</p>
          <a href={state.order.woltDelivery.woltTracking.url} target="_blank">
            {translate('delivery.agree.trackDelivery')}
          </a>
          <Link to={'/my-pages/incoming-parcels'}>{translate('delivery.agree.return')}</Link>
        </FullHeightColumn>
      ) : page === 0 ? (
        <WoltOrderForm state={state} formSubmit={handleSubmit} />
      ) : page === 1 ? (
        <WoltPayment shipmentNumber={state.shipment.shipmentNumber} selection={values} goBack={goBack} />
      ) : null}
    </AccountLayout>
  );
};
